var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'sale',
    'sequence',
    'product',
    'quantity',
    'price',
    'amount' ],"formats":{
    sequence: 'Integer4',
    quantity: 'Decimal',
    price: 'Money',
    amount: 'Money',
  },"itemsPerPage":"10","searchOnStart":true,"table":"sale_items"}})}
var staticRenderFns = []

export { render, staticRenderFns }